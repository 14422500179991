import dayjs from 'dayjs';
import useAxios from 'hooks/useAxios';

export const getEventsHistory = async (limit = 20, page = 1, filters = '') => {
  const api = useAxios();
  try {
    let timeFilter = [];
    const params = {
      limit: limit,
      page: page,
      sortBy: 'createdAt:DESC'
    };

    if (Object.keys(filters).length) {
      if (filters.users.length) {
        params['filter.user.id'] = `$in:${filters.users.map((u) => u.id).toString()}`;
      }
      if (filters.eventTypes.length) {
        params['filter.type.id'] = `$in:${filters.eventTypes.map((e) => e.id).toString()}`;
      }
      if (filters.casinos.length) {
        params['filter.casino.id'] = `$in:${filters.casinos.map((c) => c.id).toString()}`;
      }
      if (filters.countries.length) {
        params['filter.country'] = `$in:${filters.countries.map((c) => c).toString()}`;
      }
      if (filters.from) {
        timeFilter.push(`$gte:${filters.from.format('YYYY-MM-DDTHH:mm:ssZ')}`);
      }
      if (filters.to) {
        timeFilter.push(`$lte:${filters.to.format('YYYY-MM-DDTHH:mm:ssZ')}`);
      }
      if (filters.demo) {
        params['filter.user.isDemo'] = `true`;
      } else {
        params['filter.user.isDemo'] = `false`;
      }
    }
    if (timeFilter.length) {
      params['filter.createdAt'] = timeFilter;
    }
    const res = await api.get('/admin/metrics', {
      params: params
    });

    const data = res.data.data.map((event) => {
      return {
        ...event,
        casino: event?.casino?.name ? event.casino.name : '-',
        username: event?.user?.nickname != null ? event.user.nickname : '-',
        timestamp: event?.createdAt ? event.createdAt : '-',
        event_type: event?.type?.name ? event.type.name : '-',
        event_type_id: event?.type?.id ? event.type.id : '-',
        amount: event?.payload?.amount != null ? event.payload.amount : '-',
        gameType: event?.payload?.gameType ? event.payload.gameType : '-',
        country: event?.country ? event.country : '-'
      };
    });
    return {
      data: data,
      meta: res.data.meta
    };
  } catch (err) {
    console.log(err);
    return {
      data: [],
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalPages: 0,
        currentPage: 0
      }
    };
  }
};

export const getEventsAggregated = async (limit = 20, page = 1, filters = null) => {
  const api = useAxios();
  try {
    const params = {
      limit: limit,
      page: page,
      sortBy: 'createdAt:DESC'
    };

    if (Object.keys(filters).length) {
      if (filters.eventTypes.length) {
        params['id'] = `${filters.eventTypes.map((e) => e.id).toString()}`;
      }
    }
    const res = await api.get('/admin/metrics/aggregated', {
      params: params
    });

    const data = res.data.map((event) => {
      return {
        event_type: event?.name ? event.name : '-',
        calls: event?.count ? event.count : '-',
        user_calls: event?.userCount ? event.userCount : '-',
        avg_daily_calls: Math.floor(event.count / 30),
        id: event.id
      };
    });
    return {
      data: data,
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalPages: 0
      } //res.data.meta
    };
  } catch (err) {
    console.log(err);
    return {
      data: [],
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalPages: 0,
        currentPage: 0
      }
    };
  }
};

export const getTodayNumbers = async (type) => {
  try {
    const api = useAxios();
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    date = date.toJSON();

    let dateTomorrow = new Date();
    dateTomorrow.setHours(24, 0, 0, 0);
    dateTomorrow = dateTomorrow.toJSON();

    const res = await api.get(`admin/metrics/analytics-count-for-period/${type}`, {
      params: {
        limit: 100000,
        startDate: date,
        endDate: dateTomorrow
      }
    });

    return await res.data;
  } catch (error) {
    console.log(error);

    return {
      data: []
    };
  }
};

export const getChartAmount = async (type) => {
  const api = useAxios();
  try {
    let startDate = dayjs(new Date()).subtract(12, 'month')['$d'].toISOString();
    let endDate = new Date().toISOString();

    const params = {
      limit: 20,
      type: type,
      interval: 'month',
      startDate: startDate,
      endDate: endDate
    };

    const res = await api.get('metric/chartAmount/', {
      params: params
    });

    const data = res.data.map((interval) => {
      return [
        interval?.intervalStart ? new Date(interval.intervalStart).getMonth() : '-',
        interval?.amount ? interval.amount : 0
      ];
    });

    return data;
  } catch (err) {
    console.log(err);
    return [
      {
        month: '-',
        count: 0
      }
    ];
  }
};

export const getNewRegistrations = async () => {
  const api = useAxios();
  try {
    let startDate = dayjs(new Date()).subtract(12, 'month')['$d'].toISOString();
    let endDate = new Date().toISOString();

    const params = {
      limit: 20,
      type: 'registration_start',
      interval: 'month',
      startDate: startDate,
      endDate: endDate
    };

    const res = await api.get('admin/metrics/metrics-by-interval/', {
      params: params
    });

    const data = res.data.map((registrations) => {
      return {
        month: registrations?.intervalStart ? new Date(registrations.intervalStart).getMonth() + 1 : '-',
        count: registrations?.count ? registrations.count : '-'
      };
    });

    return data;
  } catch (err) {
    console.log(err);
    return [
      {
        month: '-',
        count: 0
      }
    ];
  }
};

export const getGameSessions = async () => {
  try {
    const api = useAxios();

    let dateFrom = dayjs();
    dateFrom = dateFrom.toJSON();

    let dateTo = dayjs().hour(23).minute(59);
    dateTo = dateTo.toJSON();

    const res = await api.get(`/metric/chartAmount`, {
      params: {
        limit: 1,
        startDate: dateFrom,
        endDate: dateTo,
        type: `bet`,
        interval: 'day'
      }
    });

    const amount = res.data[0].amount;

    return [amount];
  } catch (error) {
    console.log(error);
    return [0, 0, 0];
  }
};

export const trackSuccessfulLogins = async () => {
  try {
    const api = useAxios();

    const res = await api.get(`admin/metrics`, {
      params: {
        limit: 8,
        'filter.type.id': `$in:38`,
        sortBy: 'createdAt:DESC'
      }
    });

    const data = res.data.data.map((login) => {
      let date = new Date(login.createdAt);
      return {
        username: login.user?.nickname ? login.user.nickname : '-',
        date: login.createdAt ? date.toLocaleString() : '-'
      };
    });

    return data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const getStatistics = async (dateFrom = '', dateTo = '') => {
  try {
    const api = useAxios();

    if (dateFrom === '') {
      dateFrom = dayjs().date(1).month(1).year(2000);
      dateFrom = dateFrom.toJSON();
    } else {
      dateFrom = new Date(dateFrom.$d).toISOString();
    }

    if (dateTo === '') {
      dateTo = dayjs();
      dateTo = dateTo.toJSON();
    } else {
      dateTo = new Date(dateTo.$d).toISOString();
    }

    const res = await api.get(`/metric/statistics`, {
      params: {
        startDate: dateFrom,
        endDate: dateTo
      }
    });

    return res;
  } catch (error) {
    console.log(error);
    return {
      data: []
    };
  }
};
