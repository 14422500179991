/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 PRO React examples
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/DashboardNavbar';
import Footer from 'examples/Footer';
import ReportsBarChart from 'examples/Charts/BarCharts/ReportsBarChart';
import TimelineItem from 'examples/Timeline/TimelineItem';
import TimelineList from 'examples/Timeline/TimelineList';
import { DateTimePicker } from '@mui/x-date-pickers';
import { pickersLayoutClasses } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

import dataTablePlayersData from 'assets/mockData/dataTablePlayers';
// Data

// Images
import booking1 from 'assets/images/products/product-1-min.jpg';
import booking2 from 'assets/images/products/product-2-min.jpg';
import booking3 from 'assets/images/products/product-3-min.jpg';
import casino from 'assets/images/casino.png';
import InfoCard from './components/InfoCard';
import CasinoCard from './components/CasinoCard';
import GradientLineChart from 'examples/Charts/LineCharts/GradientLineChart';
import DataTable from 'components/DataTablePage/components/DataTable';
import VerticalBarChart from 'examples/Charts/BarCharts/VerticalBarChart';
import MultiLayerPieChart from 'examples/Charts/MultiLayerPieChart';
import DoubleInfoCard from './components/DoubleInfoCard';
import {
  getTodayNumbers,
  getGameStats,
  getNewRegistrations,
  getGameSessions,
  trackSuccessfulLogins,
  getChartAmount,
  getStatistics
} from 'services/analytics';
import { useEffect, useState } from 'react';
import { Card, Skeleton } from '@mui/material';
import HorizontalBarChart from 'examples/Charts/BarCharts/HorizontalBarChart';

function Dashboard() {
  const [gameWin, setGameWin] = useState('');
  const [gameLose, setGameLose] = useState('');
  const [gameBet, setGameBet] = useState('');
  const [registrationStart, setRegistrationStart] = useState('');
  const [baccarat, setBaccarat] = useState('');
  const [blackjack, setBlackjack] = useState('');
  const [gameWins, setGameWins] = useState([]);
  const [gameLoses, setGameLoses] = useState([]);
  const [correctMonths, setCorrectMonths] = useState([]);
  const [newRegistrations, setNewRegistrations] = useState([]);
  const [gameSessions, setGameSessions] = useState([]);
  const [successfulLogins, setSuccessfulLogins] = useState([]);
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const [statistics, setStatistics] = useState([]);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const handleFromChange = (date) => {
    if (to) {
      if (date > to) {
        setFrom(to);
        setTo(date);
      } else {
        setFrom(date);
      }
    } else {
      setFrom(date);
    }
  };
  const handleToChange = (date) => {
    if (from) {
      if (date < from) {
        setFrom(date);
        setTo(from);
      } else {
        setTo(date);
      }
    } else {
      setTo(date);
    }
  };

  useEffect(() => {
    getStatistics(from, to).then((res) => setStatistics(res.data));
  }, [from, to]);

  const gameData = {
    labels: correctMonths,
    datasets: [
      {
        label: 'Wins',
        data: gameWins,
        color: 'info'
      },
      {
        label: 'Losses',
        data: gameLoses
      }
    ]
  };

  const registrationsData = {
    labels: correctMonths,
    datasets: [
      {
        label: 'Registrations',
        data: newRegistrations,
        color: 'info'
      }
    ]
  };

  const sessionsData = {
    labels: ['Daily bets'],
    datasets: [
      {
        label: 'Bets',
        data: gameSessions,
        color: 'info'
      }
    ]
  };

  useEffect(() => {
    getChartAmount('win').then((res) => {
      let wins = res.map((m) => {
        m[0] === 12
          ? setCorrectMonths((prev) => [...prev, months[0]])
          : setCorrectMonths((prev) => [...prev, months[m[0]]]);
        return m[1];
      });
      setGameWins(wins);
    });
    getChartAmount('lose').then((res) => {
      let loses = res.map((m) => {
        return m[1];
      });
      setGameLoses(loses);
    });
  }, []);

  useEffect(() => {
    // getNewRegistrations().then((res) => {
    //   let registrations = res.map((m) => {
    //     return m.count;
    //   });
    //   setNewRegistrations(registrations);
    // });
  }, []);

  useEffect(() => {
    getGameSessions().then((res) => setGameSessions(res));
    getStatistics().then((res) => setStatistics(res.data));
  }, []);

  const pieData = {
    labels: ['Red', 'Blue', 'Empty', 'Empty'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19],
        backgroundColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 0)'],
        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(0, 0, 0, 0)'],
        borderWidth: 1
      },
      {
        label: '# of Votes',
        data: [12, 19],
        backgroundColor: ['rgba(54, 162, 235, 0)', 'rgba(255, 99, 132, 0)'],
        borderColor: ['rgba(54, 162, 235, 0)', 'rgba(255, 99, 132, 0)'],
        borderWidth: 1,
        weight: 0.4
      },
      {
        label: '# of Votes',
        data: [5, 19],
        backgroundColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 0)'],
        borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 0)'],
        borderWidth: 1
      },
      {
        label: '# of Votes',
        data: [12, 19],
        backgroundColor: ['rgba(54, 162, 235, 0)', 'rgba(255, 99, 132, 0)'],
        borderColor: ['rgba(54, 162, 235, 0)', 'rgba(255, 99, 132, 0)'],
        borderWidth: 1,
        weight: 5
      }
    ]
  };

  useEffect(() => {
    // getTodayNumbers('game_win').then((res) => setGameWin(res));
    // getTodayNumbers('game_lose').then((res) => setGameLose(res));
    // getTodayNumbers('game_bet').then((res) => setGameBet(res));
    // getTodayNumbers('registration_start').then((res) => setRegistrationStart(res));
    // getTodayNumbers('baccarat_session_start').then((res) => setBaccarat(res));
    // getTodayNumbers('blackjack_session_start').then((res) => setBlackjack(res));
    // trackSuccessfulLogins().then((res) => setSuccessfulLogins(res));
  }, []);

  // Action buttons for the BookingCard
  const actionButtons = (
    <>
      <Tooltip title='Refresh' placement='bottom'>
        <MDTypography variant='body1' color='primary' lineHeight={1} sx={{ cursor: 'pointer', mx: 3 }}>
          <Icon color='inherit'>refresh</Icon>
        </MDTypography>
      </Tooltip>
      <Tooltip title='Edit' placement='bottom'>
        <MDTypography variant='body1' color='info' lineHeight={1} sx={{ cursor: 'pointer', mx: 3 }}>
          <Icon color='inherit'>edit</Icon>
        </MDTypography>
      </Tooltip>
    </>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {/* <MDBox mb={3}>
          <MDTypography variant='h3' fontWeight='medium'>
            Today's numbers
          </MDTypography>
        </MDBox> */}
        {/* <MDBox display='flex' justifyContent='space-between' alignItems='center'>
          <Grid container spacing={2} direction='row' justify='center' alignItems='stretch'>
            <Grid item xs={2}>
              <InfoCard color='info' icon='trending_up' title='Total game wins' description={`${gameWin}`} />
            </Grid>
            <Grid item xs={2}>
              <InfoCard color='info' icon='trending_up' title='Total game loses' description={`${gameLose}`} />
            </Grid>
            <Grid item xs={2}>
              <InfoCard color='info' icon='trending_up' title='Total game bets' description={`${gameBet}`} />
            </Grid>
            <Grid item xs={2}>
              <InfoCard
                color='info'
                icon='trending_up'
                title='Total registrations'
                description={`${registrationStart}`}
              />
            </Grid>
            <Grid item xs={2}>
              <InfoCard color='info' icon='trending_up' title='Total baccarat sessions' description={`${baccarat}`} />
            </Grid>
            <Grid item xs={2}>
              <InfoCard color='info' icon='trending_up' title='Total blackjack sessions' description={`${blackjack}`} />
            </Grid>
          </Grid>
        </MDBox> */}
        <MDBox mt={5}>
          <Grid container spacing={3} direction='row' justify='center' alignItems='stretch'>
            <Grid item xs={8}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {gameWins.length > 0 ? (
                    <GradientLineChart
                      title='Game Wins vs. Losses ($)'
                      description='Monthly performance'
                      chart={gameData}
                      tension={0.5}
                    />
                  ) : (
                    <Skeleton height={400} />
                  )}
                </Grid>
                {/* <Grid item xs={4}>
                  <MultiLayerPieChart title='Earnings' description='24 Hours performance' chart={pieData} />
                </Grid> */}
                <Grid item xs={12}>
                  {gameSessions.length > 0 ? (
                    <HorizontalBarChart title='Daily bets' description='24 Hours performance' chart={sessionsData} />
                  ) : (
                    <Skeleton height={400} />
                  )}
                </Grid>
                {/* <Grid item xs={12}>
                  {newRegistrations.length > 0 ? (
                    <VerticalBarChart
                      title='Registrations'
                      description='Monthly performance'
                      chart={registrationsData}
                    />
                  ) : (
                    <Skeleton height={400} />
                  )}
                </Grid> */}
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox position='relative' borderRadius='lg' p={3}>
                      <MDBox mb={1}>
                        <MDTypography
                          variant='h5'
                          fontWeight='bold'
                          textTransform='capitalize'
                          fontSize='1.2rem'
                          mb={2}
                        >
                          Statistics
                        </MDTypography>
                        <MDBox sx={{ display: 'flex', justifyContent: 'space-between' }} mb={2}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              label='From'
                              ampmInClock={false}
                              closeOnSelect={true}
                              ampm={false}
                              showDaysOutsideCurrentMonth
                              value={from}
                              viewRenderers={{
                                hours: null,
                                minutes: null,
                                seconds: null
                              }}
                              onChange={handleFromChange}
                              slotProps={{
                                layout: {
                                  sx: {
                                    [`.${pickersLayoutClasses.actionBar}`]: {
                                      display: 'none'
                                    }
                                  }
                                }
                              }}
                            />
                            <DateTimePicker
                              label='To'
                              ampmInClock={false}
                              closeOnSelect={true}
                              ampm={false}
                              showDaysOutsideCurrentMonth
                              value={to}
                              viewRenderers={{
                                hours: null,
                                minutes: null,
                                seconds: null
                              }}
                              onChange={handleToChange}
                              slotProps={{
                                layout: {
                                  sx: {
                                    [`.${pickersLayoutClasses.actionBar}`]: {
                                      display: 'none'
                                    }
                                  }
                                }
                              }}
                            />
                          </LocalizationProvider>
                        </MDBox>
                        <MDTypography fontSize='13px'>
                          {from || to
                            ? `Showing results from ${from ? new Date(from).toLocaleDateString() : 'the beggining'} to ${
                                to ? new Date(to).toLocaleDateString() : 'now'
                              }`
                            : 'Showing results for all the time'}{' '}
                        </MDTypography>
                      </MDBox>
                      <MDBox>
                        <MDBox sx={{ display: 'flex', gap: '5px' }}>
                          <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                            Bets:
                          </MDTypography>
                          <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                            {` ${statistics?.betsForPeriod ? statistics?.betsForPeriod : 0}`}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox>
                        <MDBox sx={{ display: 'flex', gap: '5px' }}>
                          <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                            Bets (Amount):
                          </MDTypography>
                          <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                            {` ${
                              statistics?.betsForPeriodAmount?.amount ? statistics?.betsForPeriodAmount?.amount : 0
                            }`}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox>
                        <MDBox sx={{ display: 'flex', gap: '5px' }}>
                          <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                            Non-rollbacked bets:
                          </MDTypography>
                          <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                            {` ${statistics?.nonRollbackedBetsForPeriod ? statistics?.nonRollbackedBetsForPeriod : 0}`}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox>
                        <MDBox sx={{ display: 'flex', gap: '5px' }}>
                          <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                            Non-rollbacked bets (Amount):
                          </MDTypography>
                          <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                            {` ${
                              statistics?.nonRollbackedBetsForPeriodAmount?.amount
                                ? statistics?.nonRollbackedBetsForPeriodAmount?.amount
                                : 0
                            }`}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox>
                        <MDBox sx={{ display: 'flex', gap: '5px' }}>
                          <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                            Profit:
                          </MDTypography>
                          <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                            {` ${statistics?.profitForPeriod?.profit ? statistics?.profitForPeriod?.profit : 0}`}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>
                {/* <Grid item xs={12}>
                  <DoubleInfoCard title1='$560K' cap1='Total sales' title2='$300K' cap2='Total profit' />
                </Grid> */}
                <Grid item xs={12}>
                  {/* <TimelineList title='Login Tracker'>
                    {successfulLogins.map((login, i, { length }) => {
                      let title = `Successful Login [ ${login.username} ]`;
                      let date = `${login.date}`;
                      if (i === length - 1) {
                        return <TimelineItem icon='login' color='success' title={title} dateTime={date} lastItem />;
                      }
                      return <TimelineItem icon='login' color='success' title={title} dateTime={date} />;
                    })}
                  </TimelineList> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
